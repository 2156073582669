import { BrowserModule, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeModule } from '@theme/theme.module';
import { AppRoutingModule } from './app.routing';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { PartialsModule } from '@partials/partials.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/*Cookies*/
import { CookieService } from 'ngx-cookie-service';
import { authInterceptorProviders } from '@core/security/interceptors/auth-interceptor.service';
import { errorHandlerInterceptorProviders } from '@core/security/interceptors/error-handler-interceptor.service';
import { CustomSerializer } from '@core/store/serializer/custom-serializer';
import { EffectsArray } from '@core/store/effects';
import { metaReducers, reducers } from '@core/store';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import localeEs from '@angular/common/locales/es';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SwUpdateNotifierService } from '@utils/services/sw-update-notifier.service';

registerLocaleData(localeEs, 'es');

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ThemeModule,
		HttpClientModule,
		AppRoutingModule,
		PartialsModule,

		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),

		// NGRX
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot(EffectsArray),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'router',
			routerState: RouterState.Minimal,
			serializer: CustomSerializer,
		}),
		StoreDevtoolsModule.instrument({}),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{
			provide: LOCALE_ID,
			useValue: 'es',
		},
		Title,
		CookieService,
		authInterceptorProviders,
		errorHandlerInterceptorProviders,
		SwUpdateNotifierService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
