export class MenuConfig {
	// VENTAS
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Tablero Principal',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-chart',
					page: '/ventas/dashboard', // colocar ruta del dashboard
				},
				{
					title: 'Favoritos',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-star',
					/* page: '/transporte/gestion' colocar ruta del dashboard */
				},
			],
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
