<div ktHeader #ktHeader class="kt-container kt-header kt-grid__item sumax_header carga" [ngClass]="getBackground()" id="kt_header">
	<ng-container *ngIf="loader.progress$ | async as _value">
		<ngb-progressbar class="kt-loading-bar" *ngIf="_value || 0 > 0" [value]="_value" height="3px"></ngb-progressbar>
	</ng-container>

	<kt-header-title *ngIf="menuHeaderDisplay" [title]="title" [desc]="desc"></kt-header-title>

	<!-- <div *ngIf="!menuHeaderDisplay"></div> -->
	<kt-topbar></kt-topbar>
</div>
