import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'kt-header-title',
	templateUrl: './header-title.component.html',
	styleUrls: ['./header-title.component.scss'],
	host: {
		class: 'kt-header-title',
	},
	encapsulation: ViewEncapsulation.None,
})
export class HeaderTitleComponent {
	@Input() title = '';
	@Input() desc = '';

	fluid: boolean = true;
	clear: boolean = true;
}
