import { HttpRequest } from '@angular/common/http';
import { CookiesService } from '~shared/services/coookies.service';
import { environment } from 'environments/environment';

export function getCloneRequest(cookiesService: CookiesService, req: HttpRequest<any>) {
	const cookie = req.url.indexOf('sumax-erp-backend-maestros-sync') === -1 ? environment.idToken : environment.maestroSyncToken;

	let clonedRequest = req.clone({
		headers: req.headers.set('Authorization', getBearerAuthorization(cookiesService.getItem(cookie))),
	});

	return clonedRequest;
}

function getBearerAuthorization(cookie: string): string {
	const bearer = 'Bearer ' + cookie;
	return bearer;
}
