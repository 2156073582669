/* eslint-disable @typescript-eslint/naming-convention */
export enum EnumAcceptFileType {
	XLS = 'application/vnd.ms-excel',
	XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	DOC = 'application/msword',
	DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	TXT = 'text/plain',
	JSON = 'application/json',
	PDF = 'application/pdf',
}
