import { Injectable } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { interval } from 'rxjs';

@Injectable()
export class SwUpdateNotifierService {
	constructor(private readonly _swUpdate: SwUpdate, private readonly _matSnackBar: MatSnackBar) {}

	init(): void {
		console.log('init sw');
		this._swUpdate.available
			.pipe(
				filter((evt): evt is UpdateAvailableEvent => evt.type === 'UPDATE_AVAILABLE'),
				map((evt) => ({
					type: 'UPDATE_AVAILABLE',
					current: evt.current,
					available: evt.available,
				}))
			)
			.subscribe(() => {
				const ref = this._matSnackBar.open('Actualización disponible', 'Reload');

				ref.onAction().subscribe(() => {
					void this._swUpdate.activateUpdate().then(() => document.location.reload());
				});
			});

		if (environment.production) {
			interval(10000).subscribe(() => {
				console.log('sw checkForUpdate');
				void this._swUpdate.checkForUpdate();
			});
		}
	}
}
