import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CookiesService } from '~shared/services/coookies.service';
import { getCloneRequest } from './interceptors';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(private readonly _cookiesService: CookiesService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(getCloneRequest(this._cookiesService, req));
	}
}

export const authInterceptorProviders = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthInterceptorService,
	multi: true,
};
