import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom, tap, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import * as oficinasActions from '../actions/oficinas.actions';
import * as accesosActions from '../actions/accesos.actions';
import { AuthService } from '@core/security/services/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '@core/store';
import { currentOffices } from '../selectors/oficinas.selectors';
import { currentUser } from '../selectors/auth.selectors';
import { oficinaParams } from '../selectors/router.selectors';
import { SciOficina } from '~models/config';

@Injectable()
export class OficinasEffects {
	constructor(private actions$: Actions, private store: Store<AppState>, private auth: AuthService) {}

	cargarOficinas$ = createEffect(() =>
		this.actions$.pipe(
			ofType(oficinasActions.cargarOficinas),
			withLatestFrom(this.store.pipe(select(currentUser))),
			mergeMap(([action, user]) => {
				return this.auth.findAllSciOficinaByIdUsuario(user?.id ?? -1).pipe(
					map((usuarioOficinaPerfil) =>
						oficinasActions.cargarOficinasSuccess({
							sciUsuarioOficinaPerfil: usuarioOficinaPerfil,
						})
					),
					catchError((err) =>
						of(
							oficinasActions.cargarOficinasError({
								payload: err,
							})
						)
					)
				);
			})
		)
	);

	cargarOficinasSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(oficinasActions.cargarOficinasSuccess),
				switchMap(() => {
					return this.store.select(oficinaParams).pipe(
						filter((router) => typeof router !== 'undefined'),
						distinctUntilChanged((prev, curr) => prev !== curr)
					);
				}),
				withLatestFrom(this.store.pipe(select(currentOffices))),
				tap(([params, sciUsuarioOficinaPerfil]) => {
					let office: SciOficina | null = null;

					if (params == null) return;

					const { idOficina } = params;

					if (idOficina) {
						office = sciUsuarioOficinaPerfil.find((o) => o.sciOficina.idOficina == Number(idOficina))?.sciOficina ?? null;
					} else {
						this.store
							.select(currentOffices)
							.pipe(
								tap((offices) => {
									office = offices.find((o) => o.ctrlOficinaPrincipal)?.sciOficina ?? null;
								})
							)
							.subscribe();
					}

					if (office == null) return;

					this.store.dispatch(
						oficinasActions.cambiarOficinaSeleccionada({
							sciOficina: office,
						})
					);
				})
			),
		{ dispatch: false }
	);

	cambiarOficinaSeleccionada$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(oficinasActions.cambiarOficinaSeleccionada),
				tap((action) => this.store.dispatch(accesosActions.cargarAccesos()))
			),
		{ dispatch: false }
	);
}
