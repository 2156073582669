import { generateUUID } from './FormUtil';

import { EnumAcceptFileType } from '~shared/enums/EnumAcceptFileType';

export type Data = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

export enum ExelMessage {
	ErrorLoading = 'El archivo no se pudo cargar.',
	InvalidExtension = 'Extención no valida, solo se permiten archivos (xls,xls).',
	Success = 'La plantilla se ha importado correctamente',
	ErrorExport = 'Ocurrio un error al exportar los datos.',
	ErrorImport = 'Ocurrio un error al importar los datos.',
}

export interface ExelUtilOption {
	titleHeadboard: string;
	fileName: string;
	wsName?: string;
}

export interface ExelUtilResult {
	error: boolean;
	message?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[];
}

export function isUndefinedOrNull<T>(obj: T): boolean {
	if (typeof obj == 'undefined' || String(obj) == 'Invalid date' || obj == null) {
		return true;
	}

	if (typeof obj == 'string' && obj == '') {
		return true;
	}

	return false;
}

export function exportToJsonFile(jsonData: object, fileName = generateUUID()): void {
	const dataStr = JSON.stringify(jsonData, null, 4);
	const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

	const linkElement = document.createElement('a');
	linkElement.setAttribute('href', dataUri);
	linkElement.setAttribute('download', `${fileName.trim().replaceAll('.json', '')}.json`);
	linkElement.click();
}

export function importForFile<T = Data>(file: File): Promise<T[]> {
	const type = Object.values(EnumAcceptFileType);

	if (!type.includes(file.type as EnumAcceptFileType)) {
		return Promise.reject(ExelMessage.InvalidExtension);
	}

	return new Promise<T[]>((resolve, reject) => {
		const fileReader = new FileReader();

		fileReader.addEventListener('loadend', (ev) => {
			if (ev.target == null) return reject(null);

			const arrayBuffer = <string>ev.target.result;

			try {
				resolve(JSON.parse(arrayBuffer) as T[]);
			} catch {
				reject(ExelMessage.ErrorImport);
			}
		});

		fileReader.addEventListener('error', () => {
			void Promise.reject(ExelMessage.ErrorImport);
		});

		fileReader.readAsText(file);
	});
}
